$(document).foundation();

$(function() {
    $('li.search').off('keydown');

    $(window).on('resize', function(){
        moveNews();
    });
    $(window).trigger('resize');

    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        Foundation.reInit('sticky');
        Foundation.reInit('orbit');
    });

    var alpha = '0.85';
    var color = $('.main-menu .sticky').css('background-color');
    var comma = color.lastIndexOf(',');
    $('.title-bar .menu-icon').click(function(event){
        var a = ($('#nav').css('display') == 'block') ? 1 :alpha;
        $('.main-menu .sticky').css('background-color', color.slice(0, comma + 1) + a + ")");
    });

    function moveNews()
    {
        if($('.home').length == 0){
            return;
        }

        if (Foundation.MediaQuery.atLeast('large')) {
            if($('.news').parent().hasClass('project')) {
                $('.news').appendTo('.news-wrap');
                setTimeout(function() { $('.figure-box').css('height', 'auto'); }, 100);
            }
        } else if (Foundation.MediaQuery.atLeast('small') || Foundation.MediaQuery.atLeast('medium')) {
            if(!$('.news').parent().hasClass('project')){
                $('.news').prependTo('.project');
                new Foundation.Equalizer($('.project')).applyHeight();
            }
        }
    }
});